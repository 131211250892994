<template>
  <div>
    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: PREFIX+'s' }">{{$t('label_menu_pattern')}}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{  $t('label_logo')  }}</b-breadcrumb-item>
      </div>
    </div>
    <b-col cols="12" md="12">
      <b-card  class="mb-0">
        <!-- Header -->
        <div class="d-flex  mt-1 px-2 py-1">
          <h5 class="mb-0">
            {{ action == 'editing'? $t('label_logo') : $t('label_logo') }}
          </h5>
        </div>


      <div class="d-flex justify-content-center">
        <div  class="added-img-wrap"  >

          <img v-if="pattern_logo.length > 0" alt="logo" :src="pattern_logo[0].base64" >
          <img v-else alt="logo" :src="uploadedFile.logo? $domain+uploadedFile.logo : require('@/assets/images/no-image-2.png')" >

          <div v-if="(pattern_logo.length > 0 || (uploadedFile && uploadedFile.logo))"  @click="pattern_logo.length > 0? pattern_logo = [] :deleteItem()" class="delete-img server-deleting" >
            <feather-icon class="cursor-pointer"  icon="TrashIcon"></feather-icon>
          </div>
        </div>
      </div>

        <validation-observer
                #default="{ handleSubmit, invalid }"
                :ref="MODULE_PREFIX + '_FORM'"
                tag="form"
                class="p-2"
        >


        <b-row>
          <b-col cols="12" >
            <validation-provider
                    v-if="action == 'adding'"
                    class="mt-2"
                    :name="$t('label_logo')"
                    slim
                    #default="validationProps"
                    rules="required"
            >
              <b-form-group
                      :label="$t('label_logo')"
              >
              <file-input
                      v-model="pattern_logo"
                      class="file--uploader size--big drop-block"
                      :max-files-count='1'
                      :multiple="false"
                      :allowed-extensions="['.jpg', '.png']"
                      max-file-size='0.445'
                      :url="''"
              >
                <template #uploadBody>
                  <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                  <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                  <span>{{$t('label_add_file')}}</span>
                </template>

                <template #errorBug="fileErrors">

                  <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>

                </template>

              </file-input>
              <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                {{ validationProps.errors[0] }}
              </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

          <b-alert class="mt-2" variant="success" show>
            <div class="">{{$t('message_pattern_logo_dimensions')}}</div>
          </b-alert>

          <div class="d-flex mt-3 justify-content-end mb-2">
            <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
            >
              {{ action == 'editing'? $t('label_save') : $t('label_save') }}
            </b-button>
            <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    v-on:click="$router.go(-1)"
            >

              {{ $t('label_cancel') }}

            </b-button>
          </div>

        </validation-observer>

      </b-card>
    </b-col>

  </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import fileInput from '@/views/components/fileUploadInput'

    import {
      BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BImg, BAlert
    } from 'bootstrap-vue'

    import {MODULE_PREFIX, MODULE_PREFIX as PREFIX} from './moduleHelper'
    import {serialize} from 'object-to-formdata';


    export default {
        name: "editItem.vue",
        components: {
            // Feather,

            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            fileInput,
            BImg, BAlert,
            ValidationProvider,
            ValidationObserver,


        },
        data() {
            return {

                PREFIX,
                MODULE_PREFIX,


                action: 'adding',

                uploadedFile: false,

                pattern_logo: []
            }
        },
        created() {

            this.initData();

        },

        methods: {

            initData() {
                this.async('get', '/' + this.MODULE_PREFIX + '_logo', {}, function (resp) {

                    this.uploadedFile = resp.data;

                });

            },
            onSubmit() {
                let formData = new FormData();
                formData.append('pattern_logo', this.pattern_logo[0]);

                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX + '_logo', formData, function (resp) {
                        // this.$emit('item-added',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({name: this.MODULE_PREFIX + 's'});
                        this.uploadedFile = resp.data.item;
                    },  false,  {headers:{'Content-Type': 'multipart/form-data'}});
                }

            },
            deleteItem(){
                this.confirmDeleting((result) => {
                    this.async('delete', '/'+this.MODULE_PREFIX+'_logo/1' , {}, function (resp) {
                        this.uploadedFile = false;
                    });
                });
            }

        }
    }
</script>

<style scoped>
  .added-img-wrap{
    /*margin-bottom: 15px;*/
    border: 1px solid #BDBDBD;
    border-radius: 15px;
    align-self: center;
    display: flex;
    align-items: center;
    padding: 29px;
    position: relative;
  }
  .added-img-wrap img{
    object-fit: contain;
    width: 100%;
    max-width: 313px;
    height: 100%;
    max-height: 224px;
    border-radius: 5px;
  }
  .delete-img{
    position: absolute;
    border-radius: 50%;
    border-style: none;
    top: 7px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 3px;
    font-size: 15px;
    right: 7px;
    width: 20px;
    height: 20px;
    background-color: #EB5757;
    color: white;
  }
</style>